import { render, staticRenderFns } from "./PendingInvitation.vue?vue&type=template&id=4ab671de"
import script from "./PendingInvitation.vue?vue&type=script&lang=js"
export * from "./PendingInvitation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CAvatar: require('@chakra-ui/vue').CAvatar, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CStack: require('@chakra-ui/vue').CStack, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
