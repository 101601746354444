import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=05695f6e"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=05695f6e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CButton: require('@chakra-ui/vue').CButton, CLink: require('@chakra-ui/vue').CLink, CText: require('@chakra-ui/vue').CText, CIconButton: require('@chakra-ui/vue').CIconButton, CFlex: require('@chakra-ui/vue').CFlex, CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox})
