<template>
  <c-modal
    :is-open="isPendingInvitesOpen"
    :on-close="closePendingInvitesModal"
    is-centered
    size="md"
    style="border-radius: 10px"
    :closeOnOverlayClick="false"
  >
    <c-modal-content ref="content">
      <c-modal-header>Pending Invitations</c-modal-header>
      <c-modal-close-button />
      <c-modal-body>
        <c-stack :spacing="4">
          <c-flex align="center" v-for="invite in invites" :key="invite.id">
            <c-avatar
              size="md"
              :name="invite.company.name"
              :src="invite.company.logo"
              mr="4"
              borderWidth="0px"
              color="#fff"
            />
            <c-flex flex="1" justify="space-between" align="center">
              <c-stack :spacing="1">
                <c-text fontWeight="500">
                  {{ invite.company.name }}
                </c-text>

                <c-text fontWeight="400" fontSize="sm">
                  {{ invite.position }}
                </c-text>
                <c-flex justify="space-between">
                  <c-button
                    color="green.400"
                    fontWeight="500"
                    mr="6"
                    fontSize="sm"
                    :isLoading="isUpdatingInvites"
                    @click="acceptInvite(invite)"
                  >
                    Accept
                  </c-button>
                  <c-button
                    color="vc-orange.400"
                    fontWeight="500"
                    fontSize="sm"
                    :isLoading="isUpdatingInvites"
                    @click="declineInvite(invite)"
                  >
                    Decline
                  </c-button>
                </c-flex>
              </c-stack>
            </c-flex>
          </c-flex>

          <!-- <div style="display: flex; justify-content: center">
            <c-spinner
              v-if="isUpdatingInvites"
              color="vc-orange.400"
              thickness="2px"
            />
          </div> -->
        </c-stack>
      </c-modal-body>
      <c-modal-footer
        d="flex"
        alignItems="center"
        justify-content="space-between"
      >
        <c-button @click="closePendingInvitesModal">Accept Later</c-button>
        <c-button variant-color="vc-orange" mr="3" @click="acceptAllInvites">
          Accept All</c-button
        >
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapState, mapMutations, mapActions } from 'vuex';
import { updateTeamMember } from '@/services/company';
import { updateUser } from '@/services/user';

export default {
  name: 'PendingInvitation',
  data() {
    return {
      isUpdatingInvites: false,
      isPendingInvitesOpen: true,
    };
  },
  computed: {
    ...mapState('auth', {
      pending_invites: (state) => state.pending_invites,
      user: (state) => state.user,
    }),
    invites() {
      return this.user.teams.filter(
        (team) =>
          !team.isAccepted &&
          !team.isDeclined &&
          team.company.createdBy !== this.user.id
      );
    },
    teams() {
      return this.user.teams.filter(
        (team) => team.isAccepted && team.company.id !== this.activeCompany.id
      );
    },
  },
  async created() {
    this.getPendingInvitations();
  },

  methods: {
    ...mapActions({
      resetCompanyState: 'company/resetCompanyState',
      resetTimeline: 'timeline/resetTimeline',
    }),
    ...mapMutations({
      updateUser: 'auth/updateUser',
    }),
    closePendingInvitesModal() {
      // set pending_invites to empty array
      // this.$store.commit('auth/setPendingInvites', []);

      this.isPendingInvitesOpen = false;
      this.$emit('closeInvites');
    },
    getPendingInvitations() {
      this.isPendingInvitesOpen = this.invites.length > 0 ? true : false;
    },
    acceptInvite(invite) {
      this.isUpdatingInvites = true;
      updateTeamMember({ id: invite.id, set: { isAccepted: true } })
        .then(() => {
          this.isUpdatingInvites = false;
          this.updateUserTeam({ ...invite, isAccepted: true }, 'accept');
        })
        .catch(() => {
          this.isUpdatingInvites = false;
        });
    },
    acceptAllInvites() {
      this.isUpdatingInvites = true;
      const promises = this.invites.map((invite) => {
        return updateTeamMember({ id: invite.id, set: { isAccepted: true } });
      });
      Promise.all(promises)
        .then(() => {
          this.isUpdatingInvites = false;
          this.closePendingInvitesModal();

          // update the pending_invites state, remove the accepted invites
          this.$store.commit('auth/setPendingInvites', []);

          this.invites.forEach((invite) => {
            this.updateUserTeam({ ...invite, isAccepted: true }, 'accept');
          });
        })
        .catch(() => {
          this.isUpdatingInvites = false;
        });
    },
    declineInvite(invite) {
      this.isUpdatingInvites = true;
      updateTeamMember({ id: invite.id, set: { isDeclined: true } })
        .then(() => {
          this.updateUserTeam({ ...invite, isDeclined: true }, 'decline');
        })
        .catch(() => {
          this.isUpdatingInvites = false;
        });
    },
    async updateUserTeam(team, action) {
      const userTeams = cloneDeep(this.user.teams);
      const teamIndex = userTeams.findIndex((_team) => _team.id === team.id);
      userTeams[teamIndex] = { ...team };
      this.updateUser({ teams: userTeams });

      if (action === 'accept') {
        // this.$store.commit('auth/setPendingInvites', userTeams);

        await updateUser({
          id: this.user.id,
          set: { lastAccessedCompanyId: team.company.id },
        });
        this.isUpdatingInvites = false;
        this.closePendingInvitesModal();
        this.resetCompanyState(team.company);
        this.resetTimeline();
        this.$nextTick(() => {
          setTimeout(() => {
            location.reload();
          }, 400);
        });
      }

      if (this.teams.length === 0 && this.invites.length === 0) {
        this.closePendingInvitesModal();
        this.$router.push('/onboarding/no-team');
      }
    },
  },
};
</script>
